var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToHome },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.ACCESS_MAYORISTAS_REGISTER")) + " ")]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "container-fluid px-md-1 px-lg-4 px-1 py-5",
        style: {
          padding: "0 0",
          backgroundImage: `url(${require("@/assets/home-bg.jpg")})`,
          backgroundSize: "cover",
          "background-repeat": "no-repeat",
          "background-position": "right",
          "min-height": "80vh",
        },
      },
      [
        _c(
          "div",
          { staticClass: "container bg-white shadow px-sm-0 px-md-5 pt-3" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("p", { staticClass: "spainhn-subtitle" }, [
                  _vm._v("Registro de distribuidor"),
                ]),
              ]),
            ]),
            _c(
              "form",
              {
                staticClass: "pt-1 pb-5 mb-5",
                attrs: { novalidate: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.validarRegistro.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                    [
                      _c("p", { staticClass: "fw-bold" }, [
                        _vm._v("Datos de usuario"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Persona de contacto*",
                          type: "text",
                          name: "persona-contacto",
                          required: "",
                          outline: "",
                          invalidFeedback: "Campo obligatorio *",
                        },
                        model: {
                          value: _vm.dataForm.nombreContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "nombreContacto", $$v)
                          },
                          expression: "dataForm.nombreContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Email de usuario *",
                          type: "email",
                          name: "email-usuario",
                          required: "",
                          outline: "",
                          invalidFeedback: "Campo obligatorio *",
                        },
                        model: {
                          value: _vm.dataForm.emailContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "emailContacto", $$v)
                          },
                          expression: "dataForm.emailContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Teléfono*",
                          type: "tel",
                          required: "",
                          outline: "",
                        },
                        model: {
                          value: _vm.dataForm.telefonoContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "telefonoContacto", $$v)
                          },
                          expression: "dataForm.telefonoContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Cargo*",
                          type: "text",
                          required: "",
                          outline: "",
                        },
                        model: {
                          value: _vm.dataForm.cargo,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "cargo", $$v)
                          },
                          expression: "dataForm.cargo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "mdb-container",
                        [
                          _c("mdb-select", {
                            attrs: {
                              label: "Idioma de comunicación",
                              color: "danger",
                            },
                            on: {
                              getValue: (evt) => (_vm.dataForm.idioma = evt),
                            },
                            model: {
                              value: _vm.idiomasOptions,
                              callback: function ($$v) {
                                _vm.idiomasOptions = $$v
                              },
                              expression: "idiomasOptions",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "mdb-container",
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "Nombre de usuario",
                              color: "danger",
                            },
                            model: {
                              value: _vm.dataForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "username", $$v)
                              },
                              expression: "dataForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row w-100 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                    [
                      _c("p", { staticClass: "fw-bold" }, [
                        _vm._v("Datos de la empresa"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Razón social*",
                          type: "text",
                          name: "razon-social",
                          required: "",
                          outline: "",
                          invalidFeedback: "Campo obligatorio *",
                        },
                        model: {
                          value: _vm.dataForm.razonSocial,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "razonSocial", $$v)
                          },
                          expression: "dataForm.razonSocial",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Nombre comercial*",
                          type: "text",
                          name: "nombre-comercial",
                          required: "",
                          outline: "",
                          invalidFeedback: "Campo obligatorio *",
                        },
                        model: {
                          value: _vm.dataForm.nombreComercial,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "nombreComercial", $$v)
                          },
                          expression: "dataForm.nombreComercial",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c(
                        "mdb-container",
                        [
                          _c("mdb-select", {
                            attrs: {
                              label: "Tipo de identificación fiscal*",
                              type: "text",
                              name: "tipo-if",
                              color: "danger",
                              required: "",
                              outline: "",
                              invalidFeedback: "Campo obligatorio *",
                            },
                            on: {
                              getValue: (evt) =>
                                (_vm.dataForm.tipoIdentificacion = evt),
                            },
                            model: {
                              value: _vm.identificacionOptions,
                              callback: function ($$v) {
                                _vm.identificacionOptions = $$v
                              },
                              expression: "identificacionOptions",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Número de Identificación Fiscal*",
                          required: "",
                          outline: "",
                        },
                        model: {
                          value: _vm.dataForm.nif,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "nif", $$v)
                          },
                          expression: "dataForm.nif",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-select", {
                        attrs: {
                          label: "Tipo de empresa",
                          required: "",
                          outline: "",
                        },
                        on: {
                          getValue: (evt) => (_vm.dataForm.tipoDeAgencia = evt),
                        },
                        model: {
                          value: _vm.tiposDeNegocio,
                          callback: function ($$v) {
                            _vm.tiposDeNegocio = $$v
                          },
                          expression: "tiposDeNegocio",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _vm.numeroDeAgenciaEsRequerido
                        ? _c("mdb-input", {
                            attrs: {
                              label: "Número de agencia",
                              outline: "",
                              required: _vm.numeroDeAgenciaEsRequerido,
                            },
                            model: {
                              value: _vm.dataForm.numeroDeAgencia,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "numeroDeAgencia", $$v)
                              },
                              expression: "dataForm.numeroDeAgencia",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-select", {
                        attrs: {
                          outline: "",
                          search: "",
                          label: "Monumentos en los que está interesado",
                          multiple: "",
                        },
                        on: {
                          getValue: (evt) => {
                            _vm.dataForm.monumentosFavoritos = evt
                          },
                        },
                        model: {
                          value: _vm.monumentos,
                          callback: function ($$v) {
                            _vm.monumentos = $$v
                          },
                          expression: "monumentos",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row w-100 mt-2 gy-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                    [
                      _c("p", { staticClass: "fw-bold" }, [
                        _vm._v("Dirección de contacto"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Dirección",
                          type: "text",
                          required: "",
                          outline: "",
                          invalidFeedback: "Proporcione una dirección válida",
                        },
                        model: {
                          value: _vm.dataForm.direccionContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "direccionContacto", $$v)
                          },
                          expression: "dataForm.direccionContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Código postal",
                          type: "text",
                          size: "5",
                          required: "",
                          outline: "",
                          invalidFeedback:
                            "Proporcione un Código postal válido",
                        },
                        model: {
                          value: _vm.dataForm.codigoPostal,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "codigoPostal", $$v)
                          },
                          expression: "dataForm.codigoPostal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c(
                        "mdb-container",
                        [
                          _c("mdb-select", {
                            attrs: {
                              search: "",
                              placeholder: "País",
                              validate: "",
                              required: "",
                              outline: "",
                              invalidFeedback: "Campo obligatorio",
                              color: "danger",
                            },
                            on: {
                              getValue: (evt) =>
                                (_vm.dataForm.paisContacto = evt),
                            },
                            model: {
                              value: _vm.paises,
                              callback: function ($$v) {
                                _vm.paises = $$v
                              },
                              expression: "paises",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Provincia/Estado",
                          required: "",
                          outline: "",
                        },
                        model: {
                          value: _vm.dataForm.provinciaContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "provinciaContacto", $$v)
                          },
                          expression: "dataForm.provinciaContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-12" },
                    [
                      _c("mdb-input", {
                        attrs: {
                          label: "Ciudad",
                          color: "danger",
                          required: "",
                          outline: "",
                        },
                        model: {
                          value: _vm.dataForm.ciudadContacto,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "ciudadContacto", $$v)
                          },
                          expression: "dataForm.ciudadContacto",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 text-start" }, [
                    _c("div", { staticClass: "custom-control custom-switch" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.direccionFiscalDiferente,
                            expression: "direccionFiscalDiferente",
                          },
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "checkbox",
                          id: "direccionFiscalSwitch",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.direccionFiscalDiferente)
                            ? _vm._i(_vm.direccionFiscalDiferente, null) > -1
                            : _vm.direccionFiscalDiferente,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.direccionFiscalDiferente,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.direccionFiscalDiferente = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.direccionFiscalDiferente = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.direccionFiscalDiferente = $$c
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "direccionFiscalSwitch" },
                        },
                        [_vm._v("Direccion Fiscal diferente")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row w-100 mt-2 gy-0",
                    class: { "d-none": !_vm.direccionFiscalDiferente },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Dirección fiscal"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Dirección",
                            type: "text",
                            name: "direccion-fiscal",
                            required: "",
                            outline: "",
                            invalidFeedback: "Proporcione una dirección válida",
                          },
                          model: {
                            value: _vm.dataForm.direccionFiscal,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "direccionFiscal", $$v)
                            },
                            expression: "dataForm.direccionFiscal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Código postal",
                            type: "text",
                            size: "5",
                            name: "codigo-postal",
                            required: "",
                            outline: "",
                            invalidFeedback:
                              "Proporcione un Código postal válido",
                          },
                          model: {
                            value: _vm.dataForm.codigoPostalFiscal,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "codigoPostalFiscal", $$v)
                            },
                            expression: "dataForm.codigoPostalFiscal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-8" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-select", {
                              attrs: {
                                search: "",
                                placeholder: "País",
                                validate: "",
                                required: "",
                                outline: "",
                                name: "pais-fiscal",
                                color: "danger",
                                invalidFeedback: "Campo obligatorio",
                              },
                              on: {
                                getValue: (evt) =>
                                  (_vm.dataForm.paisFiscal = evt),
                              },
                              model: {
                                value: _vm.paises,
                                callback: function ($$v) {
                                  _vm.paises = $$v
                                },
                                expression: "paises",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Provincia/Estado",
                            name: "estado-fiscal",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.provinciaFiscal,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "provinciaFiscal", $$v)
                            },
                            expression: "dataForm.provinciaFiscal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Ciudad",
                            name: "ciudad-fiscal",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.ciudadFiscal,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "ciudadFiscal", $$v)
                            },
                            expression: "dataForm.ciudadFiscal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "row mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                    [
                      _c("p", { staticClass: "fw-bold" }, [
                        _vm._v("Terminos y condiciones"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 text-start mt-2 my-auto" },
                    [
                      _c(
                        "mdb-input",
                        {
                          staticClass: "my-auto d-flex",
                          attrs: {
                            type: "checkbox",
                            id: "terminos-condiciones",
                            name: "terminos-condiciones",
                          },
                          model: {
                            value: _vm.aceptoTerminos,
                            callback: function ($$v) {
                              _vm.aceptoTerminos = $$v
                            },
                            expression: "aceptoTerminos",
                          },
                        },
                        [
                          _c("p", { staticClass: "my-auto" }, [
                            _vm._v("Acepto el "),
                            _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://spainheritagenetwork.com/privacidad-cookies",
                                  },
                                },
                                [_vm._v("tratamiento de mis datos personales")]
                              ),
                            ]),
                            _vm._v(
                              " para poder acceder al Módulo de Distribuidores de Spainheritagenetwork*"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "mdb-btn",
                  {
                    staticClass: "spainhn-button mb-2 mt-4",
                    attrs: {
                      color: "indigo",
                      disabled: _vm.isButtonDisabled,
                      size: "lg",
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(" Enviar solicitud "),
                    _c(
                      "span",
                      { class: { "d-none": !_vm.enviandoSolicitud } },
                      [_c("b-spinner")],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }