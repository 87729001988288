<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToHome">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.ACCESS_MAYORISTAS_REGISTER')}} </p>
            </div>
        </div>

        <div class="container-fluid px-md-1 px-lg-4 px-1 py-5" :style="{padding:'0 0', backgroundImage:`url(${require('@/assets/home-bg.jpg')})`, backgroundSize:'cover', 'background-repeat': 'no-repeat','background-position': 'right', 'min-height':'80vh'}">

            <div class="container bg-white shadow px-sm-0 px-md-5 pt-3">

                <div class="row">
                    <div class="col-12">
                        <p class="spainhn-subtitle">Registro de distribuidor</p>
                    </div>
                </div>
                <form class="pt-1 pb-5 mb-5" novalidate @submit.prevent="validarRegistro" @keydown.enter="$event.preventDefault()">

                    <div class="row">

                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Datos de usuario</p>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Persona de contacto*" type="text" name="persona-contacto" v-model="dataForm.nombreContacto" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-input label="Email de usuario *" type="email" name="email-usuario" v-model="dataForm.emailContacto" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Teléfono*" type="tel" v-model="dataForm.telefonoContacto" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-input label="Cargo*" type="text" v-model="dataForm.cargo" required outline></mdb-input>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-container>
                                <mdb-select label="Idioma de comunicación" :color="'danger'" v-model="idiomasOptions" @getValue="(evt) => dataForm.idioma = evt "/>
                            </mdb-container>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-container>
                                <mdb-input label="Nombre de usuario" :color="'danger'" v-model="dataForm.username"/>
                            </mdb-container>
                        </div>

                    </div>

                    <div class="row w-100 mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Datos de la empresa</p>
                        </div>
                        
                        <div class="col-12">
                            <mdb-input label="Razón social*" type="text" name="razon-social" v-model="dataForm.razonSocial" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-12">
                            <mdb-input label="Nombre comercial*" type="text" name="nombre-comercial" v-model="dataForm.nombreComercial" required outline invalidFeedback="Campo obligatorio *"/>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-container>
                                <mdb-select label="Tipo de identificación fiscal*" type="text" name="tipo-if" color="danger"
                                    v-model="identificacionOptions"  
                                    @getValue="(evt)=>dataForm.tipoIdentificacion = evt" 
                                    required outline invalidFeedback="Campo obligatorio *"/>
                            </mdb-container>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-input label="Número de Identificación Fiscal*" v-model="dataForm.nif" required outline />
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-select label="Tipo de empresa" required outline v-model="tiposDeNegocio" @getValue="(evt) => dataForm.tipoDeAgencia = evt"/>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-input label="Número de agencia" 
                                outline v-model="dataForm.numeroDeAgencia" 
                                v-if="numeroDeAgenciaEsRequerido"
                                :required="numeroDeAgenciaEsRequerido"/>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-select 
                                v-model="monumentos" 
                                outline 
                                search 
                                label="Monumentos en los que está interesado" 
                                @getValue="(evt) => { dataForm.monumentosFavoritos = evt}"
                                multiple/>
                        </div>

                    </div>

                    <div class="row w-100 mt-2 gy-0">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Dirección de contacto</p>
                        </div>

                        <div class="col-12">
                            <mdb-input label="Dirección" type="text" v-model="dataForm.direccionContacto" required outline invalidFeedback="Proporcione una dirección válida"></mdb-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <mdb-input label="Código postal" type="text" size="5"
                                required outline v-model="dataForm.codigoPostal"
                                invalidFeedback="Proporcione un Código postal válido"/>
                        </div>

                        <div class="col-12 col-md-8">
                            <mdb-container>
                                <mdb-select search placeholder="País" validate required outline v-model="paises"
                                    @getValue="(evt) => dataForm.paisContacto = evt"
                                    invalidFeedback="Campo obligatorio" color="danger"/>
                            </mdb-container>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-input label="Provincia/Estado" v-model="dataForm.provinciaContacto" 
                                required outline/>
                        </div>
                        <div class="col-md-6 col-12">
                            <mdb-input label="Ciudad" v-model="dataForm.ciudadContacto" color="danger"
                                required outline/>
                        </div>


                        <div class="col-12 text-start">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="direccionFiscalSwitch" v-model="direccionFiscalDiferente"/>
                                <label class="custom-control-label" for="direccionFiscalSwitch">Direccion Fiscal diferente</label>
                            </div>
                        </div>
                    </div>

                    <div class="row w-100 mt-2 gy-0" v-bind:class="{'d-none':!direccionFiscalDiferente}">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Dirección fiscal</p>
                        </div>

                        <div class="col-12">
                            <mdb-input label="Dirección" type="text" v-model="dataForm.direccionFiscal" name="direccion-fiscal" required outline invalidFeedback="Proporcione una dirección válida"></mdb-input>
                        </div>

                        <div class="col-12 col-md-4">
                            <mdb-input label="Código postal" type="text" size="5" name="codigo-postal"
                                required outline v-model="dataForm.codigoPostalFiscal"
                                invalidFeedback="Proporcione un Código postal válido"/>
                        </div>

                        <div class="col-12 col-md-8">
                            <mdb-container>
                                <mdb-select search placeholder="País" validate required outline v-model="paises" name="pais-fiscal"
                                    @getValue=" (evt) => dataForm.paisFiscal = evt " color="danger"
                                    invalidFeedback="Campo obligatorio"/>
                            </mdb-container>
                        </div>

                        <div class="col-md-6 col-12">
                            <mdb-input label="Provincia/Estado" v-model="dataForm.provinciaFiscal" 
                                name="estado-fiscal"
                                required outline/>
                        </div>
                        <div class="col-md-6 col-12">
                            <mdb-input label="Ciudad" v-model="dataForm.ciudadFiscal"
                                name="ciudad-fiscal"
                                required outline/>
                        </div>

                    </div>
                    <!-- Comentado para posible uso en el futuro
                    <div class="row mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Tipo de mercado y procedencia</p>
                        </div>

                        <div class="col-12 col-md-6">
                            <mdb-container>
                                <mdb-select type="text" label="Tipo de mercado *" v-model="tiposDeMercado" @getValue=" (evt) => dataForm.tipoDeMercado = evt " required outline color="danger" />
                            </mdb-container>
                        </div>

                        <div class="col-md-6 col-0">
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-españa" name="mercado-españa" v-model="dataForm.espana" label="España" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-europa" name="mercado-europa" v-model="dataForm.europa" label="Europa" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-america" name="mercado-america" v-model="dataForm.america" label="América" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-asia" name="mercado-asia" v-model="dataForm.asia" label="Asia" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-oriente" name="mercado-oriente" v-model="dataForm.oriente" label="Oriente Medio" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-oceania" name="mercado-oceania" v-model="dataForm.oceania" label="Oceania" />
                        </div>

                        <div class="col text-start fw-bold">
                            <mdb-input type="checkbox" id="mercado-africa" name="mercado-africa" v-model="dataForm.africa" label="África" />
                        </div>

                        <div class="col">

                        </div>

                    </div> -->

                    <div class="row mt-2">
                        <div class="col-12 spainhn-divider mb-2 text-start">
                            <p class="fw-bold">Terminos y condiciones</p>
                        </div>

                        <div class="col-12 text-start mt-2 my-auto">
                            <mdb-input class="my-auto d-flex" type="checkbox" id="terminos-condiciones" name="terminos-condiciones" v-model="aceptoTerminos">
                                <p class="my-auto">Acepto el <span><a href="https://spainheritagenetwork.com/privacidad-cookies">tratamiento de mis datos personales</a></span> para poder acceder al Módulo de Distribuidores de Spainheritagenetwork*</p>
                            </mdb-input>
                        </div>

                    </div>

                    <mdb-btn 
                        color="indigo" 
                        :disabled="isButtonDisabled"
                        class="spainhn-button mb-2 mt-4" 
                        size="lg" 
                        type="submit">
                            Enviar solicitud 
                        <span v-bind:class="{'d-none':!enviandoSolicitud}">
                            <b-spinner></b-spinner>
                        </span>
                    </mdb-btn>

                </form>

            </div>

        </div>
    </default-layout>
</template>

<script>

import data from 'iso3166-2-db/countryList/dispute/UN/es';
import ApiService from '../../services/ApiService';

export default {
    data(){
        return{
            dataForm: {
                direccionContacto: null,
                ciudadContacto: null,
                provinciaContacto: null,
                nombreContacto: null,
                telefonoContacto: null,
                emailContacto: null,
                codigoPostal: null,
                paisContacto: null,
                cargo: null,
                idioma: null,
                direccionFiscal: null,
                ciudadFiscal: null,
                provinciaFiscal: null,
                horarioContacto: null,
                paisFiscal: null,
                codigoPostalFiscal: null,
                //tipoDeMercado: null,
                nif: null,
                tipoIdentificacion: null,
                nombreComercial: null,
                razonSocial: null,
                /*espana: false,
                europa: false,
                america: false,
                asia: false,
                oriente: false,
                oceania: false,
                africa: false, */
                username: null,
                tipoDeAgencia: '',
                monumentosFavoritos: [],
                numeroDeAgencia: ''
            },
            aceptoTerminos: false,
            direccionFiscalDiferente: false,
            idiomasOptions:[
                { text:"Español", value:"Español" },
                { text:"Inglés", value:"Inglés" },
                { text:"Catalán", value:"Catalán" },
            ],
            identificacionOptions:[
                { text:"NIF", value:"NIF" },
                { text:"NIE", value:"NIE" },
                { text:"CIF", value:"CIF" },
                { text:"EU VAT", value:"EU VAT" },
                { text:"Otros", value:"Otros" },
            ],
            /*tiposDeMercado:[
                { text: "Grupos", value:"Grupos" },
                { text: "Individual", value:"Individual" },
                { text: "Todos", value: "Todos" }
            ], */
            validation: {
                zipCode: {
                    valid: false,
                    validated: false
                }
            },
            tiposDeNegocio: [
                { text: "Agencia", value: 'Agencias' },
                { text: "Touroperación", value: 'Touroperación' },
                { text: "Guía", value: 'Guías' },
                { text: "Operador Turístico", value: 'Operadores Turísticos' },
                { text: "Colegio", value: 'Colegios' },
                { text: "Otro", value: 'Otros' },

            ],
            paises: [],
            enviandoSolicitud: false,
            alertaModal: false,
            mensaje: null,
            monumentos: []
        }
    },
    methods:{
        clearDataForm(){
            this.dataForm = {
                direccionContacto: null,
                ciudadContacto: null,
                provinciaContacto: null,
                nombreContacto: null,
                telefonoContacto: null,
                emailContacto: null,
                codigoPostal: null,
                paisContacto: null,
                cargo: null,
                idioma: null,
                direccionFiscal: null,
                ciudadFiscal: null,
                provinciaFiscal: null,
                horarioContacto: null,
                paisFiscal: null,
                codigoPostalFiscal: null,
                //tipoDeMercado: null,
                nif: null,
                tipoIdentificacion: null,
                nombreComercial: null,
                razonSocial: null,
                /*espana: false,
                europa: false,
                america: false,
                asia: false,
                oriente: false,
                oceania: false,
                africa: false, */
                username: null,
                tipoDeAgencia: '',
                monumentosFavoritos: [],
                numeroDeAgencia: ''
            }
        },
        checkEmptyFields() {
        let $vm = this;
        // validamos varios de los campos requeridos por el backend
        return !$vm.dataForm.nombreContacto || 
                !$vm.dataForm.emailContacto || 
                !$vm.dataForm.telefonoContacto || 
                !$vm.dataForm.cargo || 
                !$vm.dataForm.username || 
                !$vm.dataForm.paisContacto ||
                !$vm.dataForm.razonSocial || 
                !$vm.dataForm.idioma ||
                !$vm.dataForm.tipoIdentificacion || 
                !$vm.dataForm.nif || 
                !$vm.dataForm.nombreComercial || 
                !$vm.dataForm.monumentosFavoritos || 
                !$vm.dataForm.direccionContacto || 
                !$vm.dataForm.ciudadContacto || 
                !$vm.dataForm.provinciaContacto || 
                !$vm.dataForm.codigoPostal ||
                !$vm.dataForm.tipoDeAgencia || 
                (!$vm.dataForm.numeroDeAgencia && this.numeroDeAgenciaEsRequerido) || 
                !$vm.dataForm.monumentosFavoritos;
        },
        goToHome(){
            let $vm = this;
            $vm.$router.push('/')
        },
        async validarRegistro(evt){
            evt.target.classList.add('was-validated');

            this.enviandoSolicitud = true;
            try{

                if( !this.numeroDeAgenciaEsRequerido ){
                    this.dataForm.numeroDeAgencia = "";
                }
                const response = await ApiService.sendResellerRequest( this.dataForm )
    
                const mensaje = response.data.codigo;
                const error = response.data.error;
                if( error ){
                    this.mensaje = error;
                    this.$toast.error(this.mensaje);
                }
                else{
                    this.mensaje = `Su solicitud fue recibida con éxito, con el código: ${mensaje}. Le agradecemos su interes, pronto nos comunicaremos con usted`
                    this.$toast.success(this.mensaje,{
                    position: "top-right",
                    timeout: false,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    rtl: false
                    });
                    this.dataForm = {
                        direccionContacto: null,
                        ciudadContacto: null,
                        provinciaContacto: null,
                        nombreContacto: null,
                        telefonoContacto: null,
                        emailContacto: null,
                        codigoPostal: null,
                        paisContacto: null,
                        cargo: null,
                        idioma: null,
                        direccionFiscal: null,
                        ciudadFiscal: null,
                        provinciaFiscal: null,
                        horarioContacto: null,
                        paisFiscal: null,
                        codigoPostalFiscal: null,
                        //tipoDeMercado: null,
                        nif: null,
                        tipoIdentificacion: null,
                        nombreComercial: null,
                        razonSocial: null,
                        /*espana: false,
                        europa: false,
                        america: false,
                        asia: false,
                        oriente: false,
                        oceania: false,
                        africa: false, */
                        username: null,
                        tipoDeAgencia: '',
                        monumentosFavoritos: [],
                        numeroDeAgencia: ''
                    }
                }
                this.enviandoSolicitud = false;
            }
            catch( err ){
                this.mensaje = 'Error al procesar su solicitud';
                this.$toast.error(this.mensaje);
                this.enviandoSolicitud = false;
            }
        },
        validate(key, value){
            if(key === "zipCode"){
                if( this.dataForm.codigoPostal.length == 5 ){
                    this.validation['zipCode'].valid = true;
                }
                else{
                    this.validation['zipCode'].valid = false;
                }
                this.validation['zipCode'].validated = true;
            }
        }
    },
    async mounted(){
        // console.log(Object.values(data))
        const listaDePaises = Object.values(data);

        this.paises = listaDePaises.map( paisData => {
            let obj = { 
                value: paisData.iso,
                text: paisData.name
             }
             return obj;
        } )

        const listaDeMonumentos = await ApiService.getAllEdificaciones(null, 'es');
        this.monumentos = listaDeMonumentos.data.map( m => ( { text: m.nombre, value: m.id } ) );

    },
    computed:{
        numeroDeAgenciaEsRequerido(){
            if( this.dataForm.tipoDeAgencia === 'Agencias' || this.dataForm.tipoDeAgencia === 'Guías'){
                return true;
            }
            else{
                return false;
            }
        },
        isButtonDisabled() {
            return this.enviandoSolicitud || !this.aceptoTerminos || this.checkEmptyFields();
        }
    },
    watch: {
        dataForm: {
            handler: function(newVal, oldVal) {
                if( newVal.emailContacto ){
                    this.dataForm.emailContacto = newVal.emailContacto.toLowerCase();
                }
                if( newVal.username ){
                    this.dataForm.username = newVal.username.toLowerCase();
                }
            },
            deep: true
        }
    }
}
</script>